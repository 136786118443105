import TextEditor from "./TextEditor";
import React, { useState,useEffect } from "react";
import {
  mapStatetoProps,
  mapSetActiveMenuToProps,
} from "./mapRedux/mapReduxProps";
import { connect } from "react-redux";
import { Grid } from "@mui/material";

const TextEditorControl = (props) => {
  //#region Tobe Develop
  const [textInfo1, setTextInfo1] = useState("");
  const [textTobeSet1, setTextTobeSet1] = useState(undefined);
  
  const onEditorStateChange = (ctrlInfo) => {
    console.log("ctrlInfo", ctrlInfo);
    if (ctrlInfo.id === "te1") {
      setTextInfo1(ctrlInfo.value);
    }
  };

  const resetEditorStateToUndefined = (id) => {
    if (id === "te1") {
      setTextTobeSet1(undefined);
    }
  };
  //#endregion

  const getText1 = () => {
    alert(textInfo1);
  };

  const getContent1 = () => {
    navigator.clipboard.writeText(textInfo1);

    // alert("set 1 copied to clipboard")
  };

  const setDemoText1 = () => {
    setTextTobeSet1(
      '<p style="text-align:start;"><span style="color: rgb(0,0,0);font-size: medium;font-family: Times New Roman;">This is the First Line of Text Editor 1.</span></p> <p style="text-align:start;"><span style="color: rgb(0,0,0);font-size: medium;font-family: Times New Roman;"><strong>This is the Bold Line.</strong></span></p> <p style="text-align:start;"><span style="color: rgb(0,0,0);font-size: medium;font-family: Times New Roman;"><strong><em>This is the Bold &amp; Italic Line.</em></strong></span></p> <p style="text-align:start;"><span style="color: rgb(0,0,0);font-size: medium;font-family: Times New Roman;"><strong><em><ins>This is the Bold, Italic &amp; Under Line.</ins></em></strong></span>&nbsp;</p>'
    );
  };

  useEffect(() => {
    if(props.navInfo.activeMenu !== "other"){
      props.setActiveMenu("other")
    }
  })

  return (
    <div className="App">
      <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={3}>
        <Grid item>
          <h3>
            Text Editor
          </h3>
        </Grid>
        <Grid item>
          <button onClick={setDemoText1}>Set 1</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={getText1}>Get 1</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={getContent1}>Get Content1 in clipboard</button>
        </Grid>
      </Grid>
      <TextEditor
        id="te1"
        onEditorStateChange={onEditorStateChange}
        EditorState={textTobeSet1}
        resetEditorStateToUndefined={resetEditorStateToUndefined}
      />
      <br />
      <h2>HTML Content</h2>
      <br />
      {textInfo1}
      <br />
      <h2>Formated Output</h2>
      <br />
      <div
        dangerouslySetInnerHTML={{
          __html: textInfo1,
        }}
      />
    </div>
  );
}

export default connect(
  mapStatetoProps,
  mapSetActiveMenuToProps
)(TextEditorControl);
