import { setActiveMenu } from '../actions/navAction'

export const mapStatetoProps = (state) => {
    return {
        navInfo: state.navInfo,
        userInfo: state.userInfo
    }
}

export const mapSetActiveMenuToProps = (dispatch) => {
    return {
        setActiveMenu: (ActiveMenu) => { dispatch(setActiveMenu(ActiveMenu)) }
    }
}

/* 
export const mapdeletePostToProps = (dispatch) => {
    return {
        deletePost: (id) => { dispatch(deletePost(id)) }
    }
} */