import { Box, Card, CardMedia, Chip, Container, Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import client_1 from "../image/clients/client_1.jpeg";
import client_2 from "../image/clients/client_2.jpg";
import client_3 from "../image/clients/client_3.png";
import client_4 from "../image/clients/client_4.jpg";
import client_5 from "../image/clients/client_5.png";
import client_6 from "../image/clients/client_6.png";
import client_7 from "../image/clients/client_7.png";
import client_8 from "../image/clients/client_8.png";
import client_9 from "../image/clients/client_9.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/css";
class OurClient extends Component {
  render() {
    const options = {
      type: "loop",
      drag: "free",
      focus: "center",
      perPage: 4,
      perMove: 1,
      height: "19rem",
      gap: "-8rem",
      breakpoints: {
        1400: {
          perPage: 4,
          gap: "2rem",
          height: "auto",
        },
        1100: {
          perPage: 3,
          gap: "2rem",
          height: "auto",
        },
        700: {
          perPage: 3,
          gap: "18rem",
          height: "auto",
        },
        450: {
          perPage: 2,
          gap: "8rem",
          height: "auto",
          width: "auto",
        },
        400: {
          perPage: 1,
          gap: "3rem",
          height: "auto",
          // width: "auto",
        },
      },
      autoScroll: {
        speed: 1,
      },
    };

    const extensions = {
      AutoScroll,
    };
    return (
      <div>
        <Grid container justifyContent={"center"}>
          <Typography
            variant="h4"
            component="div"
            color="#000"
            style={{
              fontWeight: "bold",
              fontWeight: "800",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Our Client
          </Typography>
        </Grid>
        <Splide options={options} extensions={extensions}>
          <SplideSlide>
            <img
              src={client_1}
              alt="Image 1"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
          <SplideSlide>
            <img
              src={client_2}
              alt="Image 2"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
          <SplideSlide>
            <img
              src={client_3}
              alt="Image 3"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
          <SplideSlide>
            <img
              src={client_4}
              alt="Image 3"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
          <SplideSlide>
            <img
              src={client_5}
              alt="Image 3"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
          <SplideSlide>
            <img
              src={client_6}
              alt="Image 3"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
          <SplideSlide>
            <img
              src={client_7}
              alt="Image 3"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
          <SplideSlide>
            <img
              src={client_8}
              alt="Image 3"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
          <SplideSlide>
            <img
              src={client_9}
              alt="Image 3"
              style={{
                // height: "auto",
                height: "240px",
                // width: "auto",
                width: "240px",
              }}
            />
          </SplideSlide>
        </Splide>
      </div>
    );
  }
}

export default OurClient;
