import { CardMedia, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import React, { Component } from "react";
import aboutusimg from "./image/Aboutus/aboutus.png";
import aboutusSecImg from "./image/Aboutus/aboutussecondimg.png";
import thumb from "./image/Aboutus/thumb.png";
import pray from "./image/Aboutus/pray.png";
import flag from "./image/Aboutus/flag.png";
import defence from "./image/Aboutus/defence.png";
import { connect } from "react-redux";
import {
  mapStatetoProps,
  mapSetActiveMenuToProps,
} from "./mapRedux/mapReduxProps";
import Footer from "./home/Footer";

// const [counterOn, setCounterOn] = useState(false);
class AboutUs extends Component {
  state = {
    counterOn: false,
  };

  //#region Component
  componentDidMount() {
    this.props.setActiveMenu("AboutUs");
    document.title = "About Us | Ravi Tech World";

    // document.title = 'Ravi Tech World : About Us'
  }
  //#endregion Component

  //#region function

  setCounterOn = (newCounterValue) => {
    this.setState({ counterOn: newCounterValue });
  };

  //#endregion function
  render() {
    const styleImgOV = { height: "100px", width: "100px" };
    const styleTypoHeading = {
      fontWeight: "600",
      fontSize: "20px",
      color: "#082e47",
      textAlign: "justify",
    };
    const styleTypoBody = {
      fontWeight: "200",
      fontSize: "15px",
      color: "#082e47",
      maxWidth: "300px",
      fontWeight: 500,
      textAlign: "justify",
    };

    return (
      <div>
        <div>
          <CardMedia
            sx={{ height: "auto", marginTop: "-65px", minHeight: "300px" }}
            src={aboutusimg}
            component="img"
            alt="banner"
          />
        </div>

        {/* <div>
          <Grid
            container
            direction="row"
            spacing={6}
            justifyContent="flex-start"
          >
            <Grid item md={5}>
              <CardMedia
                sx={{ height: "auto" }}
                src={aboutusSecImg}
                component="img"
                alt="banner"
              />
            </Grid>
            <Grid item md={6} xs={10}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                spacing={5}
                // marginTop="130px"
              >
                <Grid item>
                  <Typography
                    marginTop="130px"
                    // marginLeft="150px"
                    style={{
                      fontWeight: "600",
                      fontSize: "45px",
                      color: "#082e47",
                      lineHeight: "50px",
                    }}
                  > */}
        {/* <span style={{ color: "#ED3234", fontWeight: "800" }}>
                      Who are we?
                    </span>
                    A software development company that will help your Service
                    grow */}
        {/* <span style={{ color: "#ED3234", fontWeight: "800" }}>
                      We Provide
                    </span>
                    &nbsp;Solutions Forever.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: "300",
                      fontSize: "20px",
                      color: "#082e47",
                      textAlign: "justify",
                    }}
                  > */}
        {/* The point of using Lorem Ipsum is that it has a more-or-less
                    normal
                    <br /> distribution of letters, as opposed to using 'Content
                    here, content
                    <br /> here', making it look like readable English. */}
        {/* We offer development and design services for a wide range of
                    customized software, web, and mobile applications.
                    <ul>
                      <li>That enhances Productivity.</li>
                      <li>
                        Users can get various kinds of automation, Statistics
                        &amp; reports with a single input.&nbsp;
                      </li>
                    </ul>
                    Our firm was founded with the intention of providing the
                    greatest solutions to users in accounting-related
                    managements. We launched our company approximately 11 years
                    ago, and our major goal has always been to deliver correct,
                    comprehensive, and satisfying throughput to our clients. We
                    have professional developers that constantly aim to produce
                    high-quality work. We are always ready to meet the needs of
                    the clients by utilizing cutting-edge technology, data, and
                    design. We are one of the few firms that can transform ideas
                    into products. If any client has a problem, our support team
                    will answer within a minute, and we also preserve complete
                    development documentation.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div> */}

        <div>
          <Grid
            container
            marginTop={6}
            justifyContent="space-around"
            marginBottom={4}
          >
            <Grid item xs={10} sm={10} md={5.5} lg={5.5}>
              <CardMedia
                sx={{
                  height: "500px",
                  alignItems: "center",
                }}
                component="iframe"
                title="YouTube Video"
                src="https://www.youtube.com/embed/_Pjztr7-fy0?autoplay=1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={5.5}
              lg={5.5}
              style={{ paddingRight: "20px", paddingLeft: "20px" }}
            >
              <Typography
                // variant="h4"
                style={{
                  fontSize: "45px",
                  color: "#ED3234",
                  fontWeight: "800",
                }}
              >
                We Provide
              </Typography>
              <Typography
                // variant="h5"
                style={{
                  fontSize: "45px",
                  fontWeight: "800",
                  color: "#082e47",
                }}
              >
                Solutions Forever.
              </Typography>
              <Typography
                // variant="body1"
                style={{
                  fontSize: "20px",
                  fontWeight: "300",
                  color: "#082e47",
                  textAlign: "justify",
                }}
              >
                We offer development and design services for a wide range of
                customized software, web, and mobile applications.
                <ul>
                  <li>That enhances Productivity.</li>
                  <li>
                    Users can get various kinds of automation, Statistics &amp;
                    reports with a single input.&nbsp;
                  </li>
                </ul>
                Our firm was founded with the intention of providing the
                greatest solutions to users in accounting-related managements.
                We launched our company approximately 11 years ago, and our
                major goal has always been to deliver correct, comprehensive,
                and satisfying throughput to our clients. We have professional
                developers that constantly aim to produce high-quality work. We
                are always ready to meet the needs of the clients by utilizing
                cutting-edge technology, data, and design. We are one of the few
                firms that can transform ideas into products. If any client has
                a problem, our support team will answer within a minute, and we
                also preserve complete development documentation.
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography
                marginTop="30px"
                style={{
                  fontWeight: "600",
                  fontSize: "45px",
                  color: "#022874",
                }}
              >
                Why We?
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                textAlign="center"
                justifyContent="center"
                paddingLeft="60px"
                paddingRight="60px"
                marginTop="20px"
                style={{
                  fontWeight: "200",
                  fontSize: "20px",
                  color: "#252626",
                }}
              >
                Our company was formed on the principles of honesty, integrity,
                and high-quality service. Our highly experienced staff is
                constantly striving to raise the bar in terms of the services we
                provide to our clients with the utmost dedication. We believe in
                the adage
                <br />
                "work hard, party harder."
              </Typography>
            </Grid>
            {/* <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Box
                  marginTop="45px"
                  sx={{
                    width: 400,
                    height: 230,
                    backgroundColor: "#021c45",
                    ":hover": {
                      backgroundColor: "#ed3237",
                    },
                  }}
                >
                  <Typography
                    style={{
                      color: "#fffffe",
                      fontWeight: "400",
                      fontSize: "35px",
                      marginLeft: "105px",
                      paddingTop: "20px",
                    }}
                  >
                    Our Mission
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{
                      color: "#fffffe",
                      fontSize: "20px",
                      marginLeft: "25px",
                      marginRight: "25px",
                      paddingTop: "20px",
                    }}
                  >
                    Digitise Client Needs in order to Increase Productivity and
                    Job Quality.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  marginTop="45px"
                  sx={{
                    width: 400,
                    height: 230,
                    backgroundColor: "#021c45",
                    ":hover": {
                      backgroundColor: "#ed3237",
                    },
                  }}
                >
                  <Typography
                    style={{
                      color: "#fffffe",
                      fontWeight: "400",
                      fontSize: "35px",
                      marginLeft: "115px",
                      paddingTop: "20px",
                    }}
                  >
                    Our Vision
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{
                      color: "#fffffe",
                      fontSize: "20px",
                      marginLeft: "25px",
                      marginRight: "25px",
                      paddingTop: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    Enhance your Capacity by Advanced and Easy Solution.
                  </Typography>
                </Box>
              </Grid>
            </Grid> */}
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              marginTop={4}
            >
              <Grid
                item
                xl={2}
                lg={3.5}
                md={4.5}
                xs={8}
                sx={{
                  backgroundColor: "#021c45",
                  ":hover": {
                    backgroundColor: "#ed3237",
                  },
                  display: "flex",
                  // alignItems: "center",
                  height: "240px",

                  flexDirection: "column",
                  marginTop: "25px",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#fffffe",
                      fontWeight: "400",
                      fontSize: "32px",
                      margin: "10px",
                    }}
                  >
                    Our Mission
                  </p>
                  <p
                    style={{
                      margin: "20px",
                      color: "#fffffe",
                      fontWeight: "400",
                      fontSize: "20px",
                    }}
                  >
                    Digitise Client Needs in order to Increase Productivity and
                    Job Quality.
                  </p>
                </Typography>
              </Grid>
              <Grid
                item
                xl={2}
                lg={3.5}
                md={4.5}
                xs={8}
                sx={{
                  backgroundColor: "#021c45",
                  ":hover": {
                    backgroundColor: "#ed3237",
                  },
                  display: "flex",
                  height: "240px",
                  // alignItems: "center",

                  marginTop: "25px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#fffffe",
                      fontWeight: "400",
                      fontSize: "32px",
                      margin: "10px",
                    }}
                  >
                    Our Vision
                  </p>
                  <p
                    style={{
                      margin: "20px",
                      color: "#fffffe",
                      fontWeight: "400",
                      fontSize: "20px",
                    }}
                  >
                    Enhance your Capacity by Advanced and Easy Solution.
                  </p>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid container justifyContent="center" direction="row">
            <Grid item>
              <Typography
                marginTop="120px"
                style={{
                  fontWeight: "600",
                  fontSize: "45px",
                  color: "#082e47",
                }}
              >
                Our Values
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                paddingLeft="60px"
                paddingRight="60px"
                marginTop="20px"
                align="center"
                style={{
                  fontWeight: "200",
                  fontSize: "20px",
                  color: "#252626",
                }}
              >
                We are committed to delivering high-quality work to our clients.
                Our innovative, quality-oriented approach emerges from ethical
                business philosophies, sound professional management,
                state-of-the-art technical expertise and in-depth understanding.
                Our values are evident in our business practices.
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent={"center"}
              alignItems={"center"}
              marginTop={5}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems={"center"}
                  marginTop="10px"
                  spacing={5}
                >
                  <Grid
                    item
                    sm={"auto"}
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    spacing={3}
                  >
                    <Grid item xs={"auto"}>
                      <img src={thumb} style={styleImgOV} />
                    </Grid>
                    <Grid item sm={7} md={8} style={{ width: "300px" }}>
                      <Typography style={styleTypoHeading}>
                        Customer happiness is job one
                      </Typography>
                      <Typography style={styleTypoBody}>
                        Customer happiness is described as the amount of loyalty
                        and satisfaction that your customers feel after engaging
                        with your product, services, and staff.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={"auto"}
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    spacing={3}
                  >
                    <Grid item xs={"auto"}>
                      <img src={pray} style={styleImgOV} />
                    </Grid>
                    <Grid item sm={7} md={8} style={{ width: "300px" }}>
                      <Typography style={styleTypoHeading}>
                        Demonstrate respect for everyone
                      </Typography>
                      <Typography style={styleTypoBody}>
                        Respect creates a positive environment in which
                        relationships can flourish. Without it, relationships
                        flounder.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={5}
                >
                  <Grid
                    item
                    sm={"auto"}
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    spacing={3}
                  >
                    <Grid item xs={"auto"}>
                      <img src={flag} style={styleImgOV} />
                    </Grid>
                    <Grid item sm={7} md={8} style={{ width: "300px" }}>
                      <Typography style={styleTypoHeading}>
                        Everyone is a leader
                      </Typography>
                      <Typography style={styleTypoBody}>
                        Everyone is a leader because everyone influences
                        someone.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={"auto"}
                    container
                    direction={"row"}
                    justifyContent={"center"}
                    spacing={3}
                  >
                    <Grid item xs={"auto"}>
                      <img src={defence} style={styleImgOV} />
                    </Grid>
                    <Grid item sm={7} md={8} style={{ width: "300px" }}>
                      <Typography style={styleTypoHeading}>
                        Develop Trust for each other
                      </Typography>
                      <Typography style={styleTypoBody}>
                        Both sides of the trust coin impact a leader’s ability
                        to inspire and motivate employees.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <ScrollTrigger
          onEnter={() => this.setCounterOn(true)}
          onExit={() => this.setCounterOn(false)}
        >
          <div>
            <Grid
              container
              sx={{
                backgroundColor: "#07273d",
                marginTop: "100px",
                paddingBottom: "40px",
              }}
              direction="row"
              justifyContent="center"
            >
              <Grid item>
                <Typography
                  align="center"
                  style={{
                    color: "#fffffe",
                    fontWeight: "600",
                    fontSize: "35px",
                    marginTop: "30px",
                  }}
                >
                  Things We have Achieved
                </Typography>
              </Grid>
              <Grid container direction="column">
                <Grid item>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <Typography
                        style={{
                          color: "#fffffe",
                          fontWeight: "600",
                          fontSize: "35px",
                          marginTop: "40px",
                        }}
                      >
                        500 +
                      </Typography>
                      <Typography
                        style={{
                          color: "#4499f9",
                          fontWeight: "200",
                          fontSize: "20px",
                        }}
                      >
                        Global Clients
                      </Typography>
                    </Grid>
                    <Divider
                      sx={{
                        backgroundColor: "white",
                        width: "2px",
                        height: "110px",
                        marginTop: "30px",
                        marginLeft: "40px",
                        marginRight: "40px",
                        marginBottom: "40px",
                      }}
                    />
                    <Grid item>
                      <Typography
                        style={{
                          color: "#fffffe",
                          fontWeight: "600",
                          fontSize: "35px",
                          marginTop: "40px",
                        }}
                      >
                        {this.state.counterOn && (
                          <CountUp start={0} end={11} duration={1} delay={0} />
                        )}{" "}
                        +
                      </Typography>
                      <Typography
                        style={{
                          color: "#4499f9",
                          fontWeight: "200",
                          fontSize: "20px",
                        }}
                      >
                        Years of Experience
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ScrollTrigger>
        <Footer />
      </div>
    );
  }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(AboutUs);
