import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import { Grid } from '@mui/material'
import { CheckCircleSharp, Error, Help } from '@mui/icons-material';
import processing from '../../gif/processing.gif'

const HoCtToastContainer = (CtToast) => {

    // const toastId = React.useRef(null)
    let toastId = ''

    const notifyProcessing = () => {
        toastId = toast(<ProcessingMsg />, { autoClose: false, closeOnClick: false });
    }

    const updateProcessing = (MsgType, Message) => {
        toast.update(toastId, {
            render: <Msg
                // MsgType={MsgType}
                Message={Message} />,
            type: (MsgType === 'success') ? (toast.TYPE.SUCCESS) : (
                (MsgType === 'warning') ? (toast.TYPE.WARNING) : (
                    (MsgType === 'info') ? (toast.TYPE.INFO) : (toast.TYPE.ERROR)
                )
            ),
            autoClose: (MsgType === 'success') ? (2000) : (
                (MsgType === 'info') ? (1000) : (4000)
            ),
            closeOnClick: true
            // className: 'rotateY animated'
        });
    }

    const toastMsg = (MsgType, Message) => {
        toast(<Msg
            // MsgType={MsgType} 
            Message={Message} />,
            {
                type: (MsgType === 'success') ? (toast.TYPE.SUCCESS) : (
                    (MsgType === 'warning') ? (toast.TYPE.WARNING) : (
                        (MsgType === 'info') ? (toast.TYPE.INFO) : (toast.TYPE.ERROR)
                    )
                ),
                autoClose: (MsgType === 'success') ? (2000) : (
                    (MsgType === 'info') ? (1000) : (4000)
                ),
                closeOnClick: true
                // className: 'rotateY animated'
            })
    }

    const toastErrorMsg = (Message, Id) => {
        toast.error(<Msg
            // MsgType={'error'} 
            Message={Message} />)
        if (Id) {
            document.getElementById(Id).focus()
        }
    }

    const Msg = ({ MsgType, Message }) => (
        <div style={{ textAlign: 'center' }}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                {MsgType ?
                    MsgType === 'success' ? <CheckCircleSharp /> :
                        MsgType === 'warning' ? <Help /> : <Error /> : ''
                }
                {/* <CheckCircleSharpIcon />&nbsp;&nbsp;&nbsp;Registration Successful */}&nbsp;&nbsp;&nbsp;
                {Message && Message.length > 0 ? Message : 'Process Completed & No Result Found'}
            </Grid>

        </div>
    )

    const ProcessingMsg = () => (
        <div style={{ textAlign: 'center' }}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={1} style={{ fontSize: '16px' }}>
                <img src={processing} alt='Processing' style={{ width: '100px', height: 'auto' }} />&nbsp;&nbsp;&nbsp;<b>Please wait...</b>
            </Grid>

        </div>
    )

    const onKeyDown = (event, inc, defaultAction = null, validateInput = null) => {
        if (event && event.keyCode === 13) {
            event.preventDefault()
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            const nextIndex = index + inc
            const n = form.elements.length
            if (n - 1 === nextIndex) {
                if (defaultAction) {
                    defaultAction()
                }/*  else {
                    toastMsg('error', 'No default action found to execute.')
                } */
            } else {
                if ((validateInput && validateInput(index) === true) || validateInput === null) {
                    form.elements[nextIndex].focus()
                }
            }
        } /* else {
            // toastMsg('info',event.keyCode.toString())
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            toastMsg('info', 'Index : ' + index)
        } */
    }

    return (props) => {
        return (
            <div className="ToastContainer">
                <CtToast
                    {...props}
                    notifyProcessing={notifyProcessing}
                    updateProcessing={updateProcessing}
                    toastErrorMsg={toastErrorMsg}
                    toastMsg={toastMsg}
                    onKeyDown={onKeyDown}
                />
            </div>
        )
    }
}

export default HoCtToastContainer