import React, { Component } from "react";

class Scroll extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export default Scroll;
