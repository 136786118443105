import React from "react";
import TextField from "@mui/material/TextField";

const CtTextField = ({
  id,
  label,
  disabled,
  value,
  width,
  handleOnChange,
  maxLength,
  isUpperCase,
  variant,
  onKeyDown,
  defaultAction,
  validateInput,
}) => {
  let curVariant = variant ? variant : "standard";

  // const useWidthStyle = { width: width + 'px' }
  let style = {};
  if (Number(width) > 0) {
    style.width = width + "px";
  }
  /* if (isUpperCase && isUpperCase === true) {
        style.textTransform = 'uppercase'
    } */
  // const useStyle = { ext}
  const applyMaxLength = { maxLength: maxLength, readOnly: disabled };
  const applyMaxLengthMissing = { maxLength: 1, readOnly: disabled };
  let inputPropsStyle = {};
  if (maxLength > 0) {
    inputPropsStyle.maxLength = maxLength;
  } else {
    inputPropsStyle.maxLength = 1;
  }
  if (disabled && disabled === true) {
    inputPropsStyle.readOnly = disabled;
  }
  if (isUpperCase && isUpperCase === true) {
    inputPropsStyle.style = { textTransform: "uppercase" };
  }

  const handleOnKeyDown = onKeyDown
    ? (event) => {
        onKeyDown(event, 1, defaultAction, validateInput);
      }
    : null;
  return (
    <TextField
      fullWidth
      id={id}
      name={id}
      label={label}
      // disabled={disabled}
      value={value}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      // style={width > 0 ? useWidthStyle : { textTransform: 'uppercase' }}
      style={style}
      // inputProps={maxLength > 0 ? applyMaxLength : applyMaxLengthMissing}
      inputProps={inputPropsStyle}
      variant={curVariant}
    />
  );
};

export default CtTextField;
