import React, { Component } from "react";
import { Route, Routes, BrowserRouter, Router } from "react-router-dom";
import Home from "./home/Home";
import ProductInfo from "./products/ProductInfo";
import ContactUs from "./contactUs/ContactUs";
import AboutUs from "./AboutUs";
import Service from "./Service";
import Scroll from "./Scroll";
import TextEditorControl from "./TextEditorControl";

class Navigation extends Component {
  render() {
    return (
      <div className="Navigation">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/TEC" element={<TextEditorControl />} />
          <Route path="/ProductInfo:PID" element={<ProductInfo />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Service" element={<Service />} />
        </Routes>
      </div>
    );
  }
}
export default Navigation;
