import React, { Component } from "react";
import { fetchProduct, onlineBackEndURL } from "../API/API";
import { withRouter } from "../HOC/withRouter";
import Progress from "../CustomTool/Progress";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import bg from "../image/bg.jpg";
import apmcWebImg from "../image/apmcWebImg.png";
import apmcAppImg from "../image/apmcAppImg.png";
import { connect } from "react-redux";
import {
  mapStatetoProps,
  mapSetActiveMenuToProps,
} from "../mapRedux/mapReduxProps";

class ProductInfo extends Component {
  state = {
    projectInfo: [],
    displayProgressbar: false,
    txt_maincontent1: "",
    txt_maincontent2: "",
    txt_resultcontent: "",
    txt_sidemaincontent1: "",
    txt_sidemaincontent2: "",
    txt_solutioncontent: "",
    txt_videolink: "",
    vac_mainheading: "",
    vac_mainimagepath: "",
    vac_portfolioimagepath: "",
    vac_resultheading: "",
    vac_resultimagepath: "",
    vac_sideimagepath: "",
    vac_sidemainheading: "",
    vac_solutionheading: "",
    vac_solutionimagepath: "",
    vac_productbgcolour: "",
    vac_clientimagepath: "",
    vac_doublequotesimagepath: "",
    vac_clientheading: "",
    txt_clientcontent: "",
    txt_clientname: "",
  };

  //#region Component
  componentDidMount() {
    this.loadProjectInfo();
    this.props.setActiveMenu("products");
    document.title = "Products | Ravi Tech World";
  }
  //#endregion Component

  //#region Function
  loadProjectInfo = () => {
    this.setState({ displayProgressbar: true }, () => {
      const reqData = {
        Op: "ProductList",
        bint_srno: this.props.params.PID.substring(1),
      };
      fetchProduct(reqData)
        .then((res) => {
          const projectInfo = res.data.ProductList;
          this.setState(
            {
              projectInfo,
              displayProgressbar: false,
              txt_maincontent1: projectInfo[0].txt_maincontent1,
              txt_maincontent2: projectInfo[0].txt_maincontent2,
              txt_resultcontent: projectInfo[0].txt_resultcontent,
              txt_sidemaincontent1: projectInfo[0].txt_sidemaincontent1,
              txt_sidemaincontent2: projectInfo[0].txt_sidemaincontent2,
              txt_solutioncontent: projectInfo[0].txt_solutioncontent,
              txt_videolink: projectInfo[0].txt_videolink,
              vac_mainheading: projectInfo[0].vac_mainheading,
              vac_mainimagepath: projectInfo[0].vac_mainimagepath,
              vac_portfolioimagepath: projectInfo[0].vac_portfolioimagepath,
              vac_resultheading: projectInfo[0].vac_resultheading,
              vac_resultimagepath: projectInfo[0].vac_resultimagepath,
              vac_sideimagepath: projectInfo[0].vac_sideimagepath,
              vac_sidemainheading: projectInfo[0].vac_sidemainheading,
              vac_solutionheading: projectInfo[0].vac_solutionheading,
              vac_solutionimagepath: projectInfo[0].vac_solutionimagepath,
              vac_productbgcolour: projectInfo[0].vac_productbgcolour,
              vac_clientimagepath: projectInfo[0].vac_clientimagepath,
              vac_doublequotesimagepath:
                projectInfo[0].vac_doublequotesimagepath,
              vac_clientheading: projectInfo[0].vac_clientheading,
              txt_clientcontent: projectInfo[0].txt_clientcontent,
              txt_clientname: projectInfo[0].txt_clientname,
            },
            () => {
              document.title =
                this.state.vac_mainheading + " | Ravi Tech World";
              window.scrollTo(0, 0);
            }
          );
        })
        .catch((err) => {
          alert("Network error found.");
        });
    });
  };
  //#endregion Function

  render() {
    return (
      <div>
        {/* <div>
                <Progress color='primary' display={this.state.displayProgressbar === true} />
                Project Name : {this.props.params.PID.substring(1)}
            </div> */}
        <div>
          <Grid container direction="row" justifyContent="center">
            {/* <Grid item md="12">
              <img
                src={bg}
                style={{
                  height: "205%",
                  width: "auto",
                }}
              />
            </Grid> */}

            <Grid
              container
              direction="column"
              marginLeft="15%"
              marginTop="120px"
              md="9"
              xs="12"
              sm="12"
              lg="7"
              spacing="2"
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: "30px",
                    fontWeight: "300",
                  }}
                >
                  {this.state.vac_mainheading}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "35px",
                    fontWeight: "900",
                  }}
                >
                  {this.state.txt_maincontent1}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "200",
                    marginTop: "15px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.txt_maincontent2,
                    }}
                  />
                  {/* {this.state.txt_maincontent2} */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container>
            <Grid item md="11">
              <Box
                marginTop="50px"
                sx={{
                  // width: 1400,
                  // height: 600,
                  backgroundColor: this.state.vac_productbgcolour, //"#76df76",
                  borderBottomRightRadius: 20,
                  borderTopRightRadius: 20,
                }}
              >
                <img
                  src={onlineBackEndURL + this.state.vac_mainimagepath}
                  style={{
                    // height: "100%",
                    width: "100%",
                    // marginLeft: "180px",
                    // paddingTop: "30px",
                    // paddingBottom: "20px",
                  }}
                ></img>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            direction="row"
            justifyContent={"center"}
            spacing={4}
            alignItems="center"
          >
            <Grid item md="4" xs="11">
              <img
                src={onlineBackEndURL + this.state.vac_sideimagepath}
                style={{
                  // height: "auto",
                  width: "100%",
                  // marginLeft: "100px",
                  // marginTop: "30px",
                }}
              ></img>
            </Grid>
            <Grid item md="6" xs="11">
              <Typography
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  // marginTop: "160px",
                }}
              >
                {this.state.vac_sidemainheading}
              </Typography>
              <Typography
                style={{
                  color: this.state.vac_productbgcolour, //"#109d58",
                  fontSize: "25px",
                  fontWeight: "600",
                  marginTop: "20px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.txt_sidemaincontent1,
                  }}
                />
                {/* {this.state.txt_sidemaincontent1} */}
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "200",
                  marginTop: "20px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.txt_sidemaincontent2,
                  }}
                />
                {/* {this.state.txt_sidemaincontent2} */}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            direction="row"
            sx={{ backgroundColor: "#eeeeee" }}
            justifyContent="center"
            // alignItems="center"
            marginTop="40px"
            spacing={3}
          >
            <Grid item md="7" xs="11">
              <Typography
                style={{
                  fontSize: "35px",
                  fontWeight: "800",
                  // marginTop: "50px",
                }}
              >
                {this.state.vac_solutionheading}
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "200",
                  marginTop: "30px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.txt_solutioncontent,
                  }}
                />
                {/* {this.state.txt_solutioncontent} */}
              </Typography>
              {/* <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "200",
                  marginTop: "15px",
                }}
              >
                Many desktop publishing packages and web page editors now use.
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </Typography> */}
            </Grid>
            <Grid item md="2">
              <Box
                sx={{
                  width: 300,
                  height: 300,
                  backgroundColor: this.state.vac_productbgcolour, //"#76df76",
                  borderRadius: "50%",
                  marginTop: "50px",
                  marginBottom: "50px",
                  // marginLeft: "50px",
                }}
              ></Box>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md="3">
              <Box
                sx={{
                  width: 250,
                  height: 250,
                  backgroundColor: this.state.vac_productbgcolour, //"#76df76",
                  borderRadius: "50%",
                  marginTop: "50px",
                  marginBottom: "50px",
                  // marginLeft: "140px",
                }}
              ></Box>
            </Grid>
            <Grid item md="6" xs="11">
              <Typography
                style={{
                  fontSize: "35px",
                  fontWeight: "800",
                  // marginTop: "90px",
                }}
              >
                {this.state.vac_resultheading}
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "200",
                  marginTop: "15px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.txt_resultcontent,
                  }}
                />
                {/* {this.state.txt_resultcontent} */}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container justifyContent="center" direction="row">
            <Grid item md="8">
              {this.state.txt_videolink.length > 0 ? (
                <CardMedia
                  component="video"
                  autoPlay
                  controls
                  src={onlineBackEndURL + this.state.txt_videolink}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
        <div>
          {/* ---Client Review--- */}
          {this.state.vac_doublequotesimagepath.length > 0 &&
            this.state.vac_clientheading.length > 0 &&
            this.state.txt_clientcontent.length > 0 &&
            this.state.txt_clientname.length > 0 ? (
            <Grid
              container
              direction="row"
              justifyContent={"center"}
              alignItems={"flex-start"}
              sx={{ backgroundColor: "#eeeeee" }}
            >
              <Grid item md="4">
                <Grid
                  container
                  direction={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Grid item>
                    <Box
                      sx={{
                        width: 190,
                        height: 50,
                        backgroundColor: this.state.vac_productbgcolour, //"#76df76",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item>
                    <img
                      src={onlineBackEndURL + this.state.vac_clientimagepath}
                      style={{
                        height: "auto",
                        width: "auto",
                      }}
                    ></img>
                  </Grid>
                  <Grid item style={{ marginTop: "-7px" }}>
                    <Box
                      sx={{
                        width: 190,
                        height: 50,
                        backgroundColor: this.state.vac_productbgcolour, //"#76df76",
                      }}
                    ></Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md="7">
                <img
                  src={onlineBackEndURL + this.state.vac_doublequotesimagepath}
                  style={{
                    height: "auto",
                    width: "15%",
                    marginTop: "30px",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "35px",
                    fontWeight: "800",
                    marginTop: "30px",
                  }}
                >
                  {this.state.vac_clientheading}
                </Typography>
                <Typography
                  style={{
                    alignItems: "center",
                    fontSize: "20px",
                    fontWeight: "200",
                    marginTop: "15px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.txt_clientcontent,
                    }}
                  />
                  {/* {this.state.txt_clientcontent} */}
                </Typography>
                <Typography
                  style={{
                    fontSize: "27px",
                    fontWeight: "600",
                    marginTop: "25px",
                  }}
                >
                  {this.state.txt_clientname}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStatetoProps, mapSetActiveMenuToProps)(ProductInfo)
);
