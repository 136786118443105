import { Card, Grid, Divider, CardMedia } from "@mui/material";
import { BrowserRouter as Router, Link } from "react-router-dom";
import React, { Component } from "react";
import imgBanner from "../image/banner.png";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import apmclogo from "../image/apmclogo.png";
import A from "../image/A.jpg";
import crmlogo from "../image/crmlogo.png";
import C from "../image/C.jpg";
import D from "../image/D.jpg";
import E from "../image/E.jpg";
import F from "../image/F.jpg";
import { fetchProduct, onlineBackEndURL } from "../API/API"
import Progress from '../CustomTool/Progress'

class Portfolio extends Component {

  state = {
    PortfolioData: [],
    displayProgressbar: false,
  }

  //#region Component
  componentDidMount() {
    this.loadPorfolioData()
  }
  //#endregion Component

  //#region Function
  loadPorfolioData = () => {
    this.setState({ displayProgressbar: true }, () => {
      const reqData = { 'Op': 'ProductSrImageList' }
      fetchProduct(reqData)
        .then((res) => {
          console.log(res.data.ProductSrImageList)
          let PortfolioData = res.data.ProductSrImageList
          this.setState({ PortfolioData, displayProgressbar:false })
        })
        .catch((err) => {
          alert('Network error found.')
        })
    })
  }
  //#endregion Function

  render() {
    return (
      <div>
        {/* <Progress color='primary' display={this.state.displayProgressbar === true} /> */}
        <Card
          sx={{
            backgroundColor: "#f0f0f0",
            boxShadow: "10",
            marginTop: "20px",
          }}
        >
          <CardContent>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Grid item>
                <Typography
                  style={{
                    color: "#0d3868",
                    fontSize: "30px",
                    fontWeight: "900",
                  }}
                >
                  PORTFOLIO
                </Typography>
              </Grid>
              <Grid item>
                <Divider
                  sx={{
                    backgroundColor: "#0d3868",
                    width: "40px",
                    height: "3px",
                    marginTop: "20px",
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={3}
              justifyContent="center"
              marginTop="20px"
              marginBottom="20px"
            >
              {
                this.state.PortfolioData && this.state.PortfolioData.length > 0 ?
                  this.state.PortfolioData.map((product) => {
                    return <Grid item>
                      <Link to={"ProductInfo:" + product.bint_srno}>
                        <img src={onlineBackEndURL + product.vac_portfolioimagepath} style={{ width: 200, height: 200 }} />
                        {/* <span>{product.bint_srno} : {product.vac_portfolioimagepath}</span> */}
                      </Link>
                    </Grid>
                  })
                  : ''
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Portfolio;